<template>
<div class="container-fluid">

    <!-- <div class="row"> -->
    <!-- <div class="col-sm-12"> -->
    <!-- <div class="page-title-box"> -->
    <!-- <div class="row align-items-center"> -->
    <!-- <div class="col-md-8"> -->
    <!-- <h4 class="page-title m-0">kategori layanan</h4> -->
    <!-- </div> -->
    <!-- end col -->
    <!-- </div> -->
    <!-- end row -->
    <!-- </div> -->
    <!-- end page-title-box -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12">
            <div class="card m-b-30">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-12">
                            <h4 class="mt-0 header-title">Semua daftar layanan</h4>
                        </div>

                        <div class="col-6">
                            <!-- kosong -->
                        </div>
                        <!-- <div class="col-6">

                            <div class="float-right d-none d-md-block">
                                <div class="dropdown">
                                    <button class="btn btn-success" type="button" @click="downloadReport()">
                                        <i class="ti-download mr-1"></i> Download Laporan
                                    </button>
                                    <span class="btn" style="background-color:transparent">

                                    </span>
                                    <div></div>
                                    <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#modalAdd">
                                        <i class="ti-plus mr-1"></i> Tambah
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="offset-md-8 col-md-6 col-sm-12">
                            <div class="input-group mb-3">
                                <!-- <div></div> -->
                                <button class="btn btn-primary" type="button" @click="daftarLayananAdd()">
                                    <i class="ti-plus mr-1"></i> Tambah
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-md-8 col-md-4 col-sm-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                                </div>
                                <input type="text" class="form-control" placeholder="Cari Nama Daftar Layanan" aria-describedby="basic-addon1" v-model="search" @keyup="searchData">
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover table-lg" id='category-table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th colspan="2" style="text-align:center;">Nama</th>
                                    <th colspan="2" style="text-align:center;">Kategori Layanan</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody v-if="daftar_layanan.length <= 0">
                                <tr>
                                    <td colspan="7" style="text-align:center;">No Data</td>
                                </tr>
                            </tbody>
                            <tbody v-if="daftar_layanan.length > 0">
                                <tr v-for="(data, index) in daftar_layanan" :key="data.kode_daftar_layanan">
                                    <td>{{ index+1 }}</td>
                                    <td><img :src="data.foto != null ? url+`images/admin/kategori_layanan/daftar_layanan/${data.foto}` : url+`images/no_image.png`" class='image-table-daftar_layanan'></td>
                                    <td> {{ data.nama }}</td>
                                    <td><img :src="data.foto != null ? url+`images/admin/kategori_layanan/${data.kategorilayanan.foto}` : url+`images/no_image.png`" class='image-table-daftar_layanan'></td>
                                    <td> {{ data.kategorilayanan.nama }}</td>
                                    <td>
                                        <button type="button" class='btn btn-warning' @click="daftarLayananEdit(data.kode_daftar_layanan)"><i class="ti-pencil mr-1"></i>Edit</button>
                                        <button type="button" @click="daftarLayananDelete(data.kode_daftar_layanan)" class="btn btn-danger"><i class="ti-trash mr-1"></i>Hapus</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <CPagination align="end" :active-page.sync="current_page" :pages="last_page" :click="changePage()"/> -->
                    <!-- <CPagination align="end" :active-page.sync="current_page" :pages="last_page"/> -->
                    <!-- <nav class="float-right">
                        <ul class="pagination">
                            <li class="page-item">
                                <button class="page-link" href="#" v-if="this.current_page !== this.first_page" @click="prevPage()">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </button>
                            </li>
                            <li class="page-item" v-if="this.current_page !== this.first_page">
                                <button class="page-link" href="#" v-if="this.current_page !== this.first_page" @click="prevPage()">{{ this.current_page-1 }}</button>
                            </li>
                            <li class="page-item active">
                                <button class="page-link" href="#">{{ this.current_page }}</button>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#" @click="nextPage()" v-if="this.current_page !== this.last_page">{{ this.current_page+1 }}</a>
                            </li>
                            <li class="page-item">
                                <button class="page-link" href="#" @click="nextPage()" v-if="this.current_page !== this.last_page">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                </button>
                            </li>
                        </ul>
                    </nav> -->
                </div>
            </div>
        </div>

    </div>
    <!-- Add Modal -->
    <div class="modal fade" id="modalAdd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Tambah Daftar Layanan</h5>
                    <!-- <button type="button" class="Tutup" data-dismiss="modal" aria-label="Tutup">
                        <span aria-hidden="true">&times;</span>
                        </button> -->
                </div>
                <form action="" method='POST' enctype="multipart/form-data" @submit.prevent="daftarLayananStore()">
                    <div class="modal-body">
                        <!-- <div class="alert alert-danger" v-if="errors != ''" >
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error.id">{{ error[0] }}</li>
                                </ul>
                            </div> -->
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="col-form-label">Foto</label>
                                <div class="profile-widget-header">
                                    <img alt="image" id='edit-category-image' :src="url+`images/no_image.png`" class="image-profile-daftar_layanan">
                                    <div class="profile-widget-items">

                                        <div class="profile-widget-item">
                                            <div class="col-md-6">
                                                <input required type="file" name="file" id="" v-bind:class="{'form-control':validation.foto == null, 'form-control is-invalid':validation.foto != null }" accept='image/*' @change="onChangePhotoEdit($event)"></div>
                                            <input type="hidden" name="MAX_FILE_SIZE" value="10485760">
                                        </div>
                                        <div class="invalid-feedback">

                                            {{ validation.foto != null ? validation.foto[0] :" " }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="col-form-label">Nama</label>
                                <input type="text" :class="{'form-control':validation.nama == null, 'form-control is-invalid':validation.nama != null }" value="" required="" v-model="add.nama">
                                <div class="invalid-feedback">

                                    {{ validation.nama != null ? validation.nama[0] :" " }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="">Kategori Layanan</label>
                                <select name="kode_kategori_layanan" id="" v-model="kategorilayanan.kode_kategori_layanan" :class="{'form-control':validation.kode_kategori_layanan == null, 'form-control is-invalid':validation.kode_kategori_layanan != null }" required>

                                    <!-- <option selected value="">Pilih Kategori Layanan...</option> -->
                                    <option v-for="kat in kategorilayanan" :key="kat.kode_kategori_layanan" v-bind:value="kat.kode_kategori_layanan">{{ kat.nama }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    {{ validation.kode_kategori_layanan != null ? validation.kode_kategori_layanan[0] : '' }}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button type="submit" class="btn btn-primary">Simpan</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Edit Modal -->
    <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Daftar Layanan</h5>

                </div>
                <form action="" method='POST' enctype="multipart/form-data" @submit.prevent="daftarLayananUpdate(edit.kode_daftar_layanan)">

                    <div class="modal-body">
                        <!-- <div class="alert alert-danger" v-if="errors != ''" >
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error.id">{{ error[0] }}</li>
                                </ul>
                            </div> -->
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="col-form-label">Foto</label>
                                <div class="profile-widget-header">
                                    <img alt="image" id='edit-category-image' :src="edit.foto != null ? url+`images/admin/kategori_layanan/daftar_layanan/${edit.foto}` : `/images/daftar_layanan/no_image.png`" class="image-profile-daftar_layanan">
                                    <div class="profile-widget-items">

                                        <div class="profile-widget-item">
                                            <div class="col-md-6">
                                                <input type="file" name="file" id="" v-bind:class="{'form-control':validation.foto == null, 'form-control is-invalid':validation.foto != null }" accept='image/*' @change="onChangePhotoEdit($event)"></div>
                                            <input type="hidden" name="MAX_FILE_SIZE" value="10485760">
                                        </div>
                                        <div class="invalid-feedback">

                                            {{ validation.foto != null ? validation.foto[0] :" " }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="col-form-label">Nama</label>
                                <input type="text" :class="{'form-control':validation.nama == null, 'form-control is-invalid':validation.nama != null }" value="" required="" v-model="edit.nama">
                                <div class="invalid-feedback">

                                    {{ validation.nama != null ? validation.nama[0] :" " }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="">Kategori Layanan</label>
                                <select name="kode_kategori_layanan" id="" v-model="kategorilayanan.kode_kategori_layanan" :class="{'form-control':validation.kode_kategori_layanan == null, 'form-control is-invalid':validation.kode_kategori_layanan != null }" required>

                                    <!-- <option selected value="">Pilih Kategori Layanan...</option> -->
                                    <option v-for="kat in kategorilayanan" :key="kat.kode_kategori_layanan" v-bind:value="kat.kode_kategori_layanan">{{ kat.nama }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    {{ validation.kode_kategori_layanan != null ? validation.kode_kategori_layanan[0] : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button type="submit" class="btn btn-primary">Simpan</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div><!-- container fluid -->
</template>

<script>
import Swal from 'sweetalert2';
import iziToast from 'izitoast';
import axios from "axios";
import Loader from './../../../components/Loader';
export default {
    created() {
        document.title = "Daftar Layanan - Rudaya ~ Connect The Art";
    },
    mounted() {
        this.displayData(this.page, this.search);
    },
    beforeCreate: function () {
    },
    data() {
        return {
            fullPage: false,
            loader: '',
            daftar_layanan: [],
            validation: [],
            foto: '',
            detail: {},
            passError: false,
            kategorilayanan: {},
            add: {
                nama: '',
                
            },
            edit: {
                kode_daftar_layanan: '',
                nama: '',
                kategorilayanan: {}
            },

            search: '',
            page: 1,
            first_page: 1,
            last_page: null,
            next_page: null,
            prev_page: 1,
            current_page: this.$route.query.page || 1,
            next_page_url: '',
            prev_page_url: '',

        }

    },

    methods: {
        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        FormatTanggal(date) {
            moment(date).format(
                "DD MMMM YYYY"
            )
        },
        onChangePhotoAdd() {
            this.foto = event.target.files[0];
            $('#add-category-image').attr('src', URL.createObjectURL(event.target.files[0]));
        },

        onChangePhotoEdit() {
            this.foto = event.target.files[0];
            $('#edit-category-image').attr('src', URL.createObjectURL(event.target.files[0]));
        },

        displayData(page = '', search = '') {
            // this.Loader();
            this.validation = [];
            let uri = this.url + `api/v1/daftar-layanan/all`;
            this.axios.get(uri, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                params: {
                    page: page,
                    search: this.search,

                }
            }, ).then(response => {
                console.log(response.data);
                // this.loader.hide();
                this.daftar_layanan = response.data.data;

                this.last_page = response.data.data.last_page;
                this.current_page = response.data.data.current_page;
                this.next_page = this.current_page + 1;
                this.prev_page = this.prev_page == 0 ? 1 : this.current_page - 1;
                this.next_page_url = response.data.data.next_page_url;
                this.prev_page_url = response.data.data.prev_page_url;

            });
        },
        daftarLayananAdd() {
            let uri = this.url + `api/v1/kategori-layanan`;

            this.axios.get(uri, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }).then((response) => {
                this.kategorilayanan = response.data.data;
                console.log("ini data kategori layanan");
                console.log(this.kategorilayanan);
            });
            // $("#formEdit").trigger("reset");
            $('#modalAdd').modal('toggle');
        },
        daftarLayananStore() {
            let formData = new FormData();

            formData.append('nama', this.add.nama);
            formData.append('kode_kategori_layanan', this.kategorilayanan.kode_kategori_layanan);
            formData.append('foto', this.foto);
            let uri = this.url + 'api/v1/daftar-layanan/store';
            console.log(formData);
            this.Loader();
            // simulate AJAX
            // this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token');
            this.axios.post(uri, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.loader.hide();
                    // $('#modalAdd').appendTo("body").modal('toggle');
                    // $("#formAdd").trigger("reset");
                    $('#modalAdd').modal('toggle');

                    iziToast.success({
                        title: 'Berhasil',
                        message: 'Tambah kategori layanan berhasil!',
                    });
                    this.displayData(this.page, this.search)
                    // this.$router.push({
                    //     name: 'posts'
                    // });
                }).catch(error => {
                    this.loader.hide();
                    this.validation = error.response.data.data;
                    iziToast.warning({
                        title: 'Gagal!',
                        message: 'Tambah kategori layanan gagal!',
                    })
                });

        },
        daftarLayananEdit(kode_daftar_layanan) {
            let uri = this.url + `api/v1/daftar-layanan/detail/${kode_daftar_layanan}`;

            this.axios.get(uri, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }).then((response) => {
                let uri = this.url + `api/v1/kategori-layanan`;

                this.axios.get(uri, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }).then((res) => {
                    this.kategorilayanan = res.data.data;
                    console.log("ini data kategori layanan");
                    console.log(res.data.data);
                });

                this.edit.kode_daftar_layanan = response.data.data.kode_daftar_layanan;
                this.edit = response.data.data;
            });
            // $("#formEdit").trigger("reset");
            $('#modalEdit').modal('toggle');
        },
        daftarLayananUpdate(kode_daftar_layanan) {
            let formData = new FormData();
            formData.append('kode_daftar_layanan', this.edit.kode_daftar_layanan);
            formData.append('nama', this.edit.nama);
            formData.append('kode_kategori_layanan', this.kategorilayanan.kode_kategori_layanan);
            formData.append('foto', this.foto);
            let uri = this.url + `api/v1/daftar-layanan/update`;
            this.Loader();
            // this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token');
            this.axios.post(uri, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.loader.hide();
                    // $("#formEdit").trigger("reset");
                    $('#modalEdit').modal('toggle');

                    iziToast.success({
                        title: 'Berhasil',
                        message: 'Edit kategori layanan berhasil!',
                    });
                    this.displayData(this.page, this.search);
                }).catch(error => {
                    console.log(error);
                    this.loader.hide();
                    this.validation = error.response.data.data;
                    iziToast.warning({
                        title: 'Gagal!',
                        message: 'Edit kategori layanan gagal!',
                    })
                });
        },
        daftarLayananDelete(kode_daftar_layanan, title, index) {
            Swal.fire({
                title: 'Hapus Data',
                text: "Yakin ingin menghapus kategori layanan ini ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#6777ef',
                cancelButtonColor: '#fc544b',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.Loader();
                    this.axios.delete(this.url + `api/v1/daftar-layanan/${kode_daftar_layanan}`, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        })
                        .then(response => {
                            this.daftar_layanan.splice(index, 1);
                            this.loader.hide();
                            iziToast.success({
                                title: 'Berhasil',
                                message: 'Hapus kategori layanan berhasil!',
                            });
                            // Swal.fire(
                            // 'Terhapus!',
                            // 'Post berhasil dihapus!',
                            // 'success'
                            // )

                        }).catch(error => {
                            console.log(error);
                            this.loader.hide();
                            iziToast.warning({
                                title: 'Gagal!',
                                message: 'Hapus kategori layanan gagal!',
                            })
                        });

                }
                // else{
                //     Swal.fire(
                //     'Gagal!',
                //     'Post gagal dihapus!',
                //     'error'
                //     )
                // }
            })

        },
        nextPage() {
            let nextPage = this.current_page + 1;
            // window.history.replaceState(null, null, "?page=" + nextPage);
            this.displayData(this.current_page + 1, this.search);
        },

        prevPage() {
            let prevPage = this.current_page - 1;
            // window.history.replaceState(null, null, "?page=" + prevPage);
            this.displayData(prevPage, this.search);
        },

        searchData() {
            this.displayData(1, this.search);
            // window.history.replaceState(null, null, "?page=1");
        }
    }
}
</script>

<style>
.image-table {
    width: 80px;
}

.image-table-daftar_layanan {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    /* margin-right: 10px; */
    /* object-fit: cover; */
}

.image-profile-daftar_layanan {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    object-fit: cover;
}
</style>
